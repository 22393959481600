import React, { Component } from 'react';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';
import Amplify, { Auth, Hub } from 'aws-amplify'
import awsconfig from './config'
import awsauth from './awsauth'
import './scss/style.scss';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Email App
const TheEmailApp = React.lazy(() => import('./views/apps/email/TheEmailApp'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      signedIn: JSON.parse(localStorage.getItem('signedIn')) || null
    }
  }

  componentDidMount () {
    Amplify.configure(awsconfig)
    Auth.configure({ oauth: awsauth })
    Hub.listen('auth', ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
          console.log(data['signInUserSession']['idToken']['payload']['cognito:username']);
          this.setState({ signedIn: true},() => {
            const requestOptions = {
                method: 'GET',
                headers: { 'Content-Type': 'application/json' },
              };
            localStorage.setItem('signedIn', JSON.stringify(this.state.signedIn));
            localStorage.setItem('sub', JSON.stringify(data['signInUserSession']['idToken']['payload']['sub']));
            localStorage.setItem('username', JSON.stringify(data['signInUserSession']['idToken']['payload']['cognito:username']));
            fetch('https://u7mqvlu8lg.execute-api.eu-west-1.amazonaws.com/dev/users/' + data['signInUserSession']['idToken']['payload']['sub'], requestOptions)
              .then((response) => {
                   if (response.ok) {
                      return response.json();
                    } else {
                      throw new Error('Something went wrong');
                    }
              })
              .then(
                  (result) => {
                      console.log("success");
                      localStorage.setItem('role', result['role']);
                      localStorage.setItem('finalPermission', result['final_permission']);
                      // localStorage.setItem('currentAccountCount', result['current_account_count']);
                      localStorage.setItem('currentAccountCount', 1);
                      localStorage.setItem('planExpirationDate', result['plan_expiration_date']);
                      localStorage.setItem('planStartDate', result['plan_start_date']);
                      localStorage.setItem('plan', result['plan']);
                      // console.log(result);
                      localStorage.setItem('parentId', JSON.stringify(result['parent_id'] ? result['parent_id'] : result['id']));

                      // setDisplay("block");
                  },
              ).catch((error) => {
                  console.log("error");
              });
          })
          break
        case 'signOut':
          console.log('sign out')
          this.setState({ signedIn: false})
          // this.setState({ user: null })
          break
      }
    })
  }

  render() {
    const { signedIn } = this.state;
    console.log("signedIn");
    console.log(signedIn);
    return (
      <BrowserRouter>
          <React.Suspense fallback={loading}>
            <Switch>
              {/* <Route exact path="/login" name="Login Page" render={props => <Login {...props}/>} /> */}
              <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} />
              <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
              <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />
              <Route path="/apps/email" name="Email App" render={props => <TheEmailApp {...props}/>} />
              <Route path="/" name="Home" render={props => <TheLayout {...props}/>} />
            </Switch>
          </React.Suspense>
      </BrowserRouter>
    );
  }
}

export default App;